/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Header from "./header"
import Footer from "./footer"
import "../styles/main.scss"

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet>
          <link
            href="https://fonts.googleapis.com/css?family=Lato:100,300,300i,400"
            rel="stylesheet"
          />
          <link
            href="https://fonts.googleapis.com/css?family=Caveat+Brush&display=swap"
            rel="stylesheet"
          />
          <link
            rel="stylesheet"
            type="text/css"
            href="https://use.typekit.net/uqo2qjc.css"
          />

          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
            charSet="utf-8"
          />
          <title>{data.site.siteMetadata.title}</title>
        </Helmet>
        <div>
          <Header siteTitle={data.site.siteMetadata.title} />
          {children}
          <Footer />
        </div>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
