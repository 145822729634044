import { Link, graphql, StaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Menu from "./menu"
import Img from "gatsby-image"
import style from "../styles/header.module.scss"

const Header = ({ siteTitle }) => (
  <StaticQuery
    query={graphql`
      query {
        file(relativePath: { regex: "/logo-trek.png/" }) {
          childImageSharp {
            fluid(maxHeight: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <header id="header">
        <div className={style.banner}>
          <div className={style.logo}>
            <Img fluid={data.file.childImageSharp.fluid} />
          </div>
          <div className={style.title}>
            <h1>
              <Link to=".">{siteTitle}</Link>
            </h1>
          </div>
          <div className={style.menu}>
            <Menu />
          </div>
        </div>
      </header>
    )}
  />
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
