//import styles from "./menu.module.css"
import React from "react"
import { Link } from "gatsby"

import style from "../styles/menu.module.scss"

//console.log(styles)

const ListLink = props => (
  <li className={style.li}>
    <Link to={props.to} textDecoration="none">
      {props.children}
    </Link>
  </li>
)

const Menu = () => (
  <nav id="menu">
    <ul className={style.ul}>
      <ListLink to="/" partiallyActive={true} activeStyle={{ color: "red" }}>
        {" "}
        Home
      </ListLink>
      <ListLink to="/fr/voyages-fr">Voyages</ListLink>
      <ListLink to="/fr/rando-fr">Balades</ListLink>
      <ListLink to="/fr/equipment-fr">Matériel</ListLink>
      <ListLink to="/album/">Album</ListLink>
      <ListLink to="/contact/">Contact</ListLink>
    </ul>
  </nav>
)

export default Menu
