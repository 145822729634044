import { graphql, StaticQuery } from "gatsby"
import React from "react"
import Menu from "./menu"
import style from "../styles/footer.module.scss"

const ListLink = props => (
  <li className="footer__li">
    <a href={props.to} target="_blank" rel="noopener noreferrer">
      {props.children}
    </a>
  </li>
)

const Footer = ({ data }) => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
            email
            instagram
            facebook
            googleplus
            twitter
            author
          }
        }
      }
    `}
    render={data => (
      <footer>
        <div className={style.banner}>
          <div className={style.menu}>
            <Menu />
          </div>
          <div className={style.medias}>
            <ul>
              <ListLink
                to="https://www.facebook.com/Trekathlon-2032140103578309"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="ion-social-facebook iconfb"></i>
              </ListLink>
              <ListLink to="https://twitter.com/trekathlon">
                <i className="ion-social-twitter"></i>
              </ListLink>
              <ListLink to="https://www.instagram.com/trekathlon/">
                <i className="ion-social-instagram"></i>{" "}
              </ListLink>
              <ListLink to="https://www.pinterest.com/trekathlon/">
                <i className="ion-social-pinterest"></i>{" "}
              </ListLink>
              <ListLink to="https://www.linkedin.com/">
                <i className="ion-social-linkedin-outline"></i>{" "}
              </ListLink>
            </ul>
          </div>
        </div>
        <div className={style.bottom}>
          <div className={style.copyright}>
            ©{data.site.siteMetadata.title} {new Date().getUTCFullYear()} - Last
            update : {new Date().toUTCString()}
          </div>
        </div>
      </footer>
    )}
  />
)

export default Footer
